import { Injectable } from '@angular/core';
import { type Address } from '../models/address';
import { TaskStatus } from '@clover/tasks/models/task';
import { type CompanySearchModel } from '../models/company';

@Injectable({
  providedIn: 'root',
})
export class StringHelpersService {
  public getAddressString(address: Address): string {
    if (!address) {
      return '';
    }

    let string = '';

    if (address.streetAddressLine1) {
      string += address.streetAddressLine1;
    }

    if (address.streetAddressLine2) {
      string += ` ${string.length > 0 ? ' ' + address.streetAddressLine2 : address.streetAddressLine2}`;
    }

    if (address.lastLine) {
      string += `${string.length > 0 ? ', ' + address.lastLine : address.lastLine}`;
    } else {
      if (address.city) {
        string += ` ${address.city}`;
      }

      if (address.state) {
        string += ` ${address.state}`;
      }

      if (address.zipCode) {
        string += ` ${address.zipCode}`;
      }

      string += `, ${address.country || 'United States'}`;
    }

    return string;
  }

  public getCompanyDetailsString(company: CompanySearchModel): string {
    const address = this.getAddressString(company.address);
    return `${company.numberOfEmployeesType ? company.numberOfEmployeesType.title : '0'} Employees | ${
      address || 'Company address not available'
    }`;
  }

  public getTaskStatusString(task: { status?: TaskStatus }): string {
    if (!task) {
      return '';
    }
    switch (task.status) {
      case TaskStatus.NotStarted:
        return 'tasksPage.progressTypes.notStarted';
      case TaskStatus.InProgress:
        return 'tasksPage.progressTypes.inProgress';
      case TaskStatus.Completed:
        return 'tasksPage.progressTypes.completed';
      case TaskStatus.Closed:
        return 'tasksPage.progressTypes.closed';
      case TaskStatus.Deferred:
        return 'tasksPage.progressTypes.deferred';
      case TaskStatus.WaitingForConnection:
        return 'tasksPage.progressTypes.waitingForConnection';
      default:
        return '';
    }
  }
}
